import { Global, css } from '@emotion/react';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { Box, Button, Icon, Text, Title, desktopFirstMediaQuery } from '@inflearn/ds-react';
import ShareTable from '../../components/infcon-2023/share/ShareTable';
import { navigate, type PageProps } from 'gatsby';
import { URLS } from '../../utils/urls';
import ShareScheduleDto from '../../dtos/ShareScheduleDto';
import useUserSchedule from '../../hooks/useUserSchedule';
import { useEffect, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { getSeoForHelmet } from '../../utils/Seo';

const Share = ({ location }: PageProps) => {
  const query = new URLSearchParams(location.search);
  const userId = query.get('id') ?? '';
  const userHash = query.get('hash') ?? '';

  const { data: scheduleResponse, isError } = useUserSchedule(Number(userId), userHash);

  const { name, scheduleTable } = useMemo(() => {
    if (scheduleResponse == null) {
      return {
        name: '',
        scheduleTable: []
      };
    }

    const scheduleData = new ShareScheduleDto(scheduleResponse);

    return {
      name: scheduleData.userName,
      scheduleTable: scheduleData.getSchedules()
    };
  }, [scheduleResponse]);

  useEffect(() => {
    if (isError) {
      void navigate(URLS.INFCON_2023__SESSION);
    }
  }, [isError]);

  const seo = getSeoForHelmet({
    description:
      '보고 싶은 세션들로 시간표를 만들어 친구와 공유해 보세요! - 인프런이 만드는 IT인의 축제, 인프콘으로 초대합니다. 인프콘과 함께 배우고 나누고 성장하세요. 다양한 발표 세션, 핸즈온, 네트워킹까지 만나보세요.',
    title: `${name} 님의 인프콘 시간표는? - INFCON 2023`,
    fullUrl: `${URLS.INFCON_2023_SHARE(Number(userId), userHash, name)}`
  });

  return (
    <>
      <Helmet>{seo}</Helmet>
      <Global styles={{ '.footer_bottom': { display: 'none !important' } }} />
      <section css={styleShare}>
        <Title order={1} color="dark.9" css={styleShareTitle}>
          {name} 님의 시간표
        </Title>
        <Text color="gray.9" css={styleShareSubTitle}>
          원하는 세션을 추가해 내 시간표도 만들어보세요!
        </Text>
        <a href={URLS.INFCON_2023__SESSION} css={{ marginBottom: '40px' }}>
          <Button
            variant="outline"
            color="dark"
            radius="xl"
            size="xl"
            css={{
              ':hover': {
                backgroundColor: '#25262b',
                color: 'white'
              }
            }}
            rightIcon={<Icon icon={faArrowRight} />}>
            내 시간표 만들러 가기
          </Button>
        </a>
        <Box mb={40} css={{ width: '100%' }}>
          <ShareTable schedulesTable={scheduleTable} />
        </Box>
      </section>
    </>
  );
};

const styleShare = css({
  boxSizing: 'border-box',
  flexShrink: 0,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '80px 16px 80px 16px',
  backgroundColor: '#ebf7ff',
  overflow: 'visible',
  position: 'relative',
  alignContent: 'center',
  flexWrap: 'nowrap'
});

const styleShareTitle = css({
  marginBottom: '12px',
  fontSize: '54px',
  textAlign: 'center',
  [desktopFirstMediaQuery.mobile]: {
    fontSize: '32px'
  }
});

const styleShareSubTitle = css({
  marginBottom: '24px',
  fontSize: '24px',
  textAlign: 'center',
  fontWeight: 'medium',
  [desktopFirstMediaQuery.mobile]: {
    fontSize: '16px'
  }
});

export default Share;
